import wapp from 'whatsapp-widget'

const FormElement = document.getElementById('whatsapp')
const TriggerElement = document.querySelector('.wa-widget-toggle');

new wapp(FormElement);

document.getElementById('url-input').value = window.location.href
document.addEventListener('click', function(event) {
  if (!FormElement.contains(event.target) && !TriggerElement.contains(event.target)) {
    FormElement.querySelector('.wa-widget-content').classList.remove('expanded')
  }
})
